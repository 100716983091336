#accesscam, #capture{
    background: rgb(89, 141, 220);
    border-radius: 3px;
    height: 26px;
    color: white;
    padding-right:4px;
    padding-left:4px;

  } 
/* CSS Spinner */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto; /* Centering the spinner */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

