/* Custom CSS for removing number input spinners */
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
    @apply appearance-none;
    margin: 0;
}

.no-spinner {
    -moz-appearance: textfield;
}
