@media print {
    .no-print {
        display: none;
    }

    body {
        margin: 0;
        padding: 0;
    }

    .page {
        width: 21cm; /* A4 width */
        height: 29.7cm; /* A4 height */
        margin: 0 auto;
        padding: 1cm;
        box-sizing: border-box;
    }

    .voucher-content {
        font-family: 'Times New Roman', Times, serif;
        font-size: 12pt;
    }
}
